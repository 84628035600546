import { Role } from 'generated/types'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { ASSET_DATA_ROUTES } from '../config'

export const VIDEO_LABEL = 'Video'

export const ASSET_DATA_VIDEO_ROUTES: RouteConfig<'create' | 'edit'> = {
  create: {
    label: `Create new ${VIDEO_LABEL}`,
    permissions: Role.Editor,
    uri: () => '/asset-data/videos/new',
  },
  edit: {
    label: `Edit ${VIDEO_LABEL}`,
    permissions: Role.Editor,
    uri: (concertId: string) => `/asset-data/videos/${encodeURIComponent(concertId)}`,
  },
  index: {
    label: 'Videos',
    permissions: Role.Editor,
    uri: () => '/asset-data/videos',
  },
}

export const VIDEO_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: ASSET_DATA_ROUTES.index.uri(), title: ASSET_DATA_ROUTES.index.label },
  { href: ASSET_DATA_VIDEO_ROUTES.index.uri(), title: ASSET_DATA_VIDEO_ROUTES.index.label },
]
