import { Role } from 'generated/types'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { CORE_DATA_ROUTES } from '../config'

export const GROUP_LABEL = 'Group'

export const CORE_DATA_GROUP_ROUTES: RouteConfig<'create' | 'edit'> = {
  create: {
    label: 'Create new Group',
    permissions: Role.Editor,
    uri: () => '/core-data/groups/new',
  },
  edit: {
    label: 'Edit Group',
    permissions: Role.Editor,
    uri: (id: string) => `/core-data/groups/${encodeURIComponent(id)}`,
  },
  index: {
    label: 'Groups',
    permissions: Role.Editor,
    uri: () => '/core-data/groups',
  },
}

export const CORE_GROUP_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: CORE_DATA_ROUTES.index.uri(), title: CORE_DATA_ROUTES.index.label },
  { href: CORE_DATA_GROUP_ROUTES.index.uri(), title: CORE_DATA_GROUP_ROUTES.index.label },
]
