import { UserGroupIcon } from '@heroicons/react/24/solid'
import { Role } from 'generated/types'
import Head from 'next/head'

import { CardElement } from 'components/elements/card'
import { PageIntro } from 'components/elements/page-intro'
import AlbumIcon from 'public/assets/album.svg'
import ArtistIcon from 'public/assets/artist.svg'
import VodIcon from 'public/assets/asset-data.svg'
import LiveConcertIcon from 'public/assets/live-concert.svg'
import PartnerIcon from 'public/assets/partners.svg'
import VideoIcon from 'public/assets/video.svg'
import WorkIcon from 'public/assets/work.svg'
import { getTranslation } from 'utils/i18n/translate'

import { ASSET_DATA_ALBUM_ROUTES } from './asset-data/albums/config'
import { ASSET_DATA_LIVE_CONCERT_ROUTES } from './asset-data/live-concerts/config'
import { ASSET_DATA_VIDEO_ROUTES } from './asset-data/videos/config'
import { ASSET_DATA_VOD_ROUTES } from './asset-data/vod-concerts/config'
import { CORE_DATA_ARTIST_ROUTES } from './core-data/artists/config'
import { CORE_DATA_GROUP_ROUTES } from './core-data/groups/config'
import { CORE_DATA_PARTNER_ROUTES } from './core-data/partners/config'
import { CORE_DATA_WORK_ROUTES } from './core-data/works/config'

const t = getTranslation('home_page')

export function HomePage() {
  return (
    <div>
      <Head>
        <title>DG Stage</title>
      </Head>
      <PageIntro headline={t('DGStage Plus Admin UI')} />
      <main className="mt-4">
        <section className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <CardElement
            description={t('Concerts that are streamed live or their re-runs.')}
            href={ASSET_DATA_LIVE_CONCERT_ROUTES.index.uri()}
            icon={LiveConcertIcon}
            title={t('Live Concerts')}
          />
          <CardElement
            description={t('Concerts after completed live performance and re-runs.')}
            href={ASSET_DATA_VOD_ROUTES.index.uri()}
            icon={VodIcon}
            title={t('Video-On-Demand Concerts')}
          />
          <CardElement
            description={t('Other Video Clips, eg. Interviews, Documentaries')}
            href={ASSET_DATA_VIDEO_ROUTES.index.uri()}
            icon={VideoIcon}
            title={t('Videos')}
          />
          <CardElement
            description={t('Audio albums')}
            href={ASSET_DATA_ALBUM_ROUTES.index.uri()}
            icon={AlbumIcon}
            title={t('Albums')}
          />
          <CardElement
            description={t('Both Performers and Composers, eg. "Beethoven", "Lang, Lang"')}
            href={CORE_DATA_ARTIST_ROUTES.index.uri()}
            icon={ArtistIcon}
            title={t('Artists')}
          />
          <CardElement
            description={t('Several artists who perform together, eg. "Berlin Philharmonics"')}
            href={CORE_DATA_GROUP_ROUTES.index.uri()}
            icon={UserGroupIcon}
            title={t('Groups')}
          />
          <CardElement
            description={t('Curators, Festivals, Production Companies, Recording Companies, Sponsors and Venues')}
            href={CORE_DATA_PARTNER_ROUTES.index.uri()}
            icon={PartnerIcon}
            title={t('Partners')}
          />
          <CardElement
            description={t('A single creation by an artist, eg. "Symphony Nr. 3 in D-Minor"')}
            href={CORE_DATA_WORK_ROUTES.index.uri()}
            icon={WorkIcon}
            title={t('Works')}
          />
        </section>
        <p className="mt-4 max-w-4xl text-sm text-gray-500">
          {t(
            'These are shortcuts to the most used functionalities. You can access more features from the menu on the left.',
          )}
        </p>
      </main>
    </div>
  )
}

export function getStaticProps() {
  return { props: { minimumRequiredRole: Role.Editor } }
}
