import { Role } from 'generated/types'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { ASSET_DATA_ROUTES } from '../config'

export const VOD_CONCERT_LABEL = 'VoD-Concert'

export const ASSET_DATA_VOD_ROUTES: RouteConfig<'create' | 'createPerformanceWork' | 'edit' | 'editPerformanceWork'> = {
  create: {
    label: `Create new ${VOD_CONCERT_LABEL}`,
    permissions: Role.Editor,
    uri: () => '/asset-data/vod-concerts/new',
  },
  createPerformanceWork: {
    label: `Create ${VOD_CONCERT_LABEL} Performance Work`,
    permissions: Role.Editor,
    uri: (concertId: string) => `/asset-data/vod-concerts/${encodeURIComponent(concertId)}/performance-works/new`,
  },
  edit: {
    label: `Edit ${VOD_CONCERT_LABEL}`,
    permissions: Role.Editor,
    uri: (id: string) => `/asset-data/vod-concerts/${encodeURIComponent(id)}`,
  },
  editPerformanceWork: {
    label: 'Edit Performance Work',
    permissions: Role.Editor,
    uri: (concertId: string, workId: string) =>
      `/asset-data/vod-concerts/${encodeURIComponent(concertId)}/performance-works/${encodeURIComponent(workId)}`,
  },
  index: {
    label: `${VOD_CONCERT_LABEL}`,
    permissions: Role.Editor,
    uri: () => '/asset-data/vod-concerts',
  },
}

export const VOD_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: ASSET_DATA_ROUTES.index.uri(), title: ASSET_DATA_ROUTES.index.label },
  { href: ASSET_DATA_VOD_ROUTES.index.uri(), title: ASSET_DATA_VOD_ROUTES.index.label },
]
