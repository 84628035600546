import { Role } from 'generated/types'

import type { BreadcrumbItemProps } from 'components/elements/breadcrumbs'
import type { RouteConfig } from 'utils/ts/shared-types'

import { CORE_DATA_ROUTES } from '../config'

export const WORK_LABEL = 'Core Work'

export const CORE_DATA_WORK_ROUTES: RouteConfig<'choose' | 'copy' | 'create' | 'edit'> = {
  choose: {
    label: `How do you want to create ${WORK_LABEL}?`,
    permissions: Role.Editor,
    uri: () => '/core-data/works/choose-creation-method',
  },
  copy: {
    label: 'Copy from R2-Work',
    permissions: Role.Editor,
    uri: () => '/core-data/works/copy',
  },
  create: {
    label: `Create new ${WORK_LABEL}`,
    permissions: Role.Editor,
    uri: () => '/core-data/works/new',
  },
  edit: {
    label: `Edit ${WORK_LABEL}`,
    permissions: Role.Editor,
    uri: (id: string) => `/core-data/works/${encodeURIComponent(id)}`,
  },
  index: {
    label: 'Core Works',
    permissions: Role.Editor,
    uri: () => '/core-data/works',
  },
}

export const CORE_WORK_BREADCRUMBS: BreadcrumbItemProps[] = [
  { href: CORE_DATA_ROUTES.index.uri(), title: CORE_DATA_ROUTES.index.label },
  { href: CORE_DATA_WORK_ROUTES.index.uri(), title: CORE_DATA_WORK_ROUTES.index.label },
]
